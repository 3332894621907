body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}
p {
  margin: 0
}
.m-t-5{
  margin-top: 5px !important
}

.CustomMenu{
   z-index: 111111 !important; 
}
.CustomMenu .MuiList-root {
padding-top: 3px !important;
padding-bottom: 3px !important;
}

::-webkit-scrollbar{
  width: 0.4em;
}
::-webkit-scrollbar-track{
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
}
::-webkit-scrollbar-thumb{
  background-color: rgb(97 97 97 / 90%);
  outline: 1px solid slategrey;
  border-radius: 5px,
}